import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, NgIf } from '@angular/common';
import { DatePickerComponent } from '../../../../components/date-picker.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { VersionDirective } from 'src/app/directives/version.directive';
import { DatePickerV2Component } from 'src/app/components/date-picker-v2.component';

@Component({
  selector: 'app-detail-general-planning',
  templateUrl: './detail-general-planning.component.html',
  styles: [],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormGroupComponent,
    VersionDirective,
    DatePickerComponent,
    NgIf,
    DatePickerV2Component,
    DatePipe,
    TranslateModule,
  ],
})
export class DetailGeneralPlanningComponent {
  @Input() dateRangeValid: boolean;
  @Input() generalForm: FormGroup;
  public showPeriod: boolean = false;
}
