<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'project.list.title' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <button
        *appVersion="1"
        (click)="openModal()"
        type="button"
        class="secondary"
        appAccess
        role="manager"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--blue.svg'"></span>
        <span>{{ 'project.list.new_update' | translate }}</span>
      </button>
      <a
        *appVersion="2"
        (click)="openModal()"
        type="button"
        class="secondary"
        appAccess
        role="manager"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.list.new_update' | translate }}</span>
      </a>
      <a
        (click)="createNewProject()"
        class="primary"
        appAccess
        role="projectAdmin"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.list.new_project' | translate }}</span>
      </a>
    </div>
  </div>
</div>
<div class="projects projects--list">
  <div class="container">
    <form [formGroup]="filterForm">
      <div class="bouwapp-toolbar hasSorting">
        <div>
          <input
            [attr.placeholder]="'project.list.search' | translate"
            type="search"
            class="search"
            formControlName="search"
            id="search"
          />
        </div>
        <div>
          <select
            [compareWith]="compareSort"
            [attr.title]="'project.list.sort' | translate"
            id="sort"
            class="select select--m"
            formControlName="sort"
          >
            <option *ngFor="let sort of sortItems" [ngValue]="sort">
              {{ sort.label | translate }}
            </option>
          </select>
        </div>
      </div>

      <div class="projects__overview flex flex-row">
        <div class="filter">
          <div class="filter__title" *appVersion="2">
            <h3>{{ 'project.list.filter.title' | translate }}</h3>
          </div>

          <div class="filter__group" formArrayName="statuses">
            <label class="filter__label">{{
              'project.list.filter.status.title' | translate
            }}</label>
            <div class="checkboxes">
              <div
                *ngFor="let status of allStatuses; let index = index"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="status{{ status }}"
                  [formControlName]="index"
                />
                <label for="status{{ status }}"
                  >{{ 'project.list.filter.status.' + status | translate }}
                  <span *ngIf="statusCount"
                    >({{ statusCount[status] }})</span
                  ></label
                >
              </div>
            </div>
          </div>

          <div class="filter__group" formArrayName="customers">
            <label class="filter__label">{{
              'project.list.filter.customer.title' | translate
            }}</label>
            <div class="checkboxes">
              <div
                class="checkbox"
                *ngFor="let customer of customers; let index = index"
              >
                <input
                  type="checkbox"
                  id="customer{{ customer.id }}"
                  [formControlName]="index"
                />
                <label for="customer{{ customer.id }}">{{
                  customer.name
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="projects__overview__items">
          <p *ngIf="projects && projects.length">
            {{
              'project.list.result.title' | translate : { projects: totalItems }
            }}
          </p>

          <ul
            [hidden]="!projects"
            infiniteScroll
            (scrolled)="loadProjects()"
            [infiniteScrollContainer]="'div.app__content'"
            [infiniteScrollDisabled]="projects?.length == totalItems"
            [fromRoot]="true"
          >
            <li
              *ngFor="let project of projects"
              (click)="openProject(project)"
              class="project"
            >
              <div class="project__top">
                <div class="project__image">
                  <div
                    class="project__image__self"
                    *ngIf="project.coverImageThumbnails"
                    [style.backgroundImage]="getCoverImageByProject(project)"
                  ></div>
                  <div
                    class="project__image__self noImage"
                    *ngIf="!project.coverImageThumbnails"
                  ></div>
                  <div class="project__badge" *ngIf="project.isParentProject">
                    {{ 'project.list.item.type.parent' | translate }}
                  </div>
                  <div
                    class="project__badge child"
                    *ngIf="project.hasParentProject && !project.isParentProject"
                  >
                    {{ 'project.list.item.type.child' | translate }}
                  </div>
                </div>
                <div *appVersion="1" class="project__heading">
                  <p>
                    {{ project.name }}
                  </p>
                  <div>
                    <span [ngClass]="[getStatusClass(project)]">
                      {{ project.status | translate }}
                    </span>
                    <span>{{ project.participants }}</span>
                  </div>
                </div>
                <div *appVersion="2" class="project__heading">
                  <div>
                    <span [ngClass]="[getStatusClass(project)]">
                      {{ project.status | translate }}
                    </span>
                    <span>{{ project.participants }}</span>
                  </div>
                  <p>
                    {{ project.name }}
                  </p>
                  <div class="followers">
                    <span>
                      <span inlineSVG="/assets/v2/img/icons/person.svg"></span>
                      <span>{{ project.amountOfFollowers }}</span>
                    </span>
                    <span>
                      ({{
                        'project.list.item.followers.gained'
                          | translate : { gained: project.lastProjectStars }
                      }})
                    </span>
                  </div>
                </div>
              </div>
              <div *appVersion="1" class="project__content">
                <ul>
                  <li *ngIf="project.startDate">
                    <div
                      class="icon"
                      [inlineSVG]="'/assets/img/icons/range--orange.svg'"
                    ></div>
                    <p>
                      {{ project.startDate | dateType : project.startType }}
                      <span *ngIf="project.endDate">
                        - {{ project.endDate | dateType : project.endType }}
                      </span>
                    </p>
                  </li>
                  <li>
                    <div
                      class="icon"
                      [inlineSVG]="'/assets/img/icons/favorite--orange.svg'"
                    ></div>
                    <p>
                      {{ project.starredBy }}
                      <span>{{ 'project.list.item.starred' | translate }}</span>
                    </p>
                  </li>
                  <li>
                    <div
                      class="icon"
                      [inlineSVG]="'/assets/img/icons/chat--orange.svg'"
                    ></div>
                    <p>
                      {{ project.openTicketCount }}
                      <span>{{
                        'project.list.item.open_tickets' | translate
                      }}</span>
                    </p>
                  </li>
                  <li *ngIf="project.isParentProject">
                    <div
                      class="icon"
                      [inlineSVG]="'/assets/img/icons/location--orange.svg'"
                    ></div>
                    <p>
                      {{ project.childrenProjectsCount }}
                      <span>{{
                        'project.list.item.projects' | translate
                      }}</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div
                class="project__parent"
                *ngIf="project.hasParentProject && !project.isParentProject"
                [innerHTML]="
                  'project.list.item.projects.text'
                    | translate : { name: project.parentProject.name }
                "
              ></div>
            </li>
          </ul>

          <app-loader *ngIf="loading"></app-loader>

          <div class="no-results" *ngIf="projects && projects.length === 0">
            {{ 'project.list.empty' | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<app-customer-picker
  [withProjectPermissions]="true"
  #customerPicker
></app-customer-picker>
<app-update-picker
  [customers]="customers"
  [projects]="projectShortListForUpdates"
></app-update-picker>
<app-modal #successModal class="success-modal">
  <section class="project-success">
    <span [inlineSVG]="'/assets/img/icons/confetti.svg'"></span>
    <h5 *appVersion="2">
      {{ 'projects.detail.concept.success.title' | translate }}
    </h5>
    <p
      [innerHTML]="
        'projects.detail.concept.success.description'
          | translate : { projectName: project?.name }
      "
    ></p>
    <div class="flex buttons">
      <button
        class="button button--m button--outlined bg--grey--hover"
        [routerLink]="['/projects', project?.slug, 'general']"
      >
        {{ 'projects.detail.concept.success.link.settings' | translate }}
      </button>
      <button
        class="button button--m button--flat bg--blue"
        [routerLink]="['/projects', project?.slug, 'updates']"
      >
        {{ 'projects.detail.concept.success.link.updates' | translate }}
      </button>
    </div>
  </section>
</app-modal>
