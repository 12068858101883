import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { UserDataService } from '../../../services/user-data.service';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from '../../../interfaces/customer';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../directives/loading.directive';
import { InputFilePreviewComponent } from '../../../components/input-file-preview.component';
import { InputFileComponent } from '../../../components/input-file.component';
import { FormGroupComponent } from '../../../components/form-group.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { CustomerPickerComponent } from 'src/app/components/customer-picker.component';
import { VersionDirective } from 'src/app/directives/version.directive';

@Component({
  selector: 'app-default-profile-company',
  templateUrl: 'company.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    SuccessMessageComponent,
    FormChangeDetectorDirective,
    ReactiveFormsModule,
    FormGroupComponent,
    InputFileComponent,
    InputFilePreviewComponent,
    LoadingDirective,
    TranslateModule,
    CustomerPickerComponent,
    VersionDirective,
  ],
})
export class CompanyComponent {
  public form: FormGroup;
  public formSaved = false;
  public customer: Customer;

  loading = false;
  uploading = false;

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private userDataService: UserDataService,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.loadCustomer(params['id']);
      }
    });
  }

  async loadCustomer(id: number): Promise<void> {
    this.customer = await this.customerService.fetch(id);

    this.form.patchValue(this.customer as any);
  }

  /**
   * @returns {void}
   */
  createForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      logo: [''],
      description: [''],
      zipcode: [''],
      streetNumber: ['', [Validators.maxLength(10)]],
      street: [''],
      city: [''],
      email: ['', [this.customEmailValidator]],
      phoneNumber: [''],
    });
  }

  /**
   * @returns {void}
   */
  async save(): Promise<void> {
    if (this.uploading) {
      return;
    }

    this.formSaved = false;
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      try {
        this.loading = true;

        const data = this.form.getRawValue();
        data.id = this.customer.id;

        const customer: Customer = await this.customerService.update(data);
        await this.userDataService.storeCustomer(customer);

        this.formSaved = true;
      } catch (error) {
        this.errorService.parseErrorsToForm(this.form, error.error, {
          logoFile: 'logo',
        });
      } finally {
        this.loading = false;
      }
    } else {
      this.errorService.scrollToInvalidFormGroup();
    }
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
  }

  /**
   * Custom validator, because empty email will result in an invalid control
   * @param control
   * @returns {any}
   */
  private customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return Validators.email(control);
  }

  public changeCustomer(customer: Customer) {
    this.customer = customer;
    this.form.patchValue(this.customer as any);
  }
}
