import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from '../../../interfaces/customer';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { UserService } from 'src/app/services/user.service';
import { Project } from 'src/app/interfaces/project';
import { SecurityVoter } from 'src/app/security/security-voter';
import { ManageUsersService } from 'src/app/components/manage-users/manage-users.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from '../../../components/error-message.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { ListUsersComponent } from '../../../components/manage-users/list-users.component';
import { ManageUsersComponent } from '../../../components/manage-users/manage-users.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-user-admin',
  templateUrl: 'user-admin.component.html',
  standalone: true,
  imports: [
    ManageUsersComponent,
    ListUsersComponent,
    SuccessMessageComponent,
    ErrorMessageComponent,
    TranslateModule,
    NgIf,
  ],
})
export class UserAdminComponent {
  public formSaved = false;
  public customer: Customer;
  public projects: Project[];

  public inviteSaved: boolean = false;
  public cannotSave: boolean = false;
  public editSaved: boolean = false;
  public formLoading: boolean = false;
  public deleted: boolean = false;
  public loading: boolean = false;

  public projectUsers: ProjectUser[] = [];
  public editingMapping: ProjectUser;

  public showCreateProjectUser = false;
  public projectUser: ProjectUser;

  constructor(
    private errorService: ErrorService,
    private projectUserService: ProjectUserService,
    private userService: UserService,
    private manageUserService: ManageUsersService,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.loadCustomer(params['id']);
      }
    });
  }

  async loadCustomer(id: number): Promise<void> {
    this.loading = true;
    this.customer = await this.customerService.fetch(id);
    this.loadUsers();
  }

  private async loadUsers(): Promise<void> {
    try {
      this.loading = true;

      await this.projectUserService
        .fetchListForCustomer(this.customer, true)
        .then((result) => {
          this.projectUsers = result.filter(
            (user) =>
              user.customersProjectUser ||
              user.projects.some(
                (project) => project.permissionType === 'create'
              )
          );
        });
    } catch (error) {
      this.errorService.logError(error);
    } finally {
      this.loading = false;
    }
  }

  public async create(data: {
    projectUser: ProjectUser;
    formData: any;
  }): Promise<void> {
    const { permission } = this.manageUserService.extractPrefixAndValue(
      data.formData.permissionType
    );
    data.formData.permissionType = permission;
    this.cannotSave = false;
    this.inviteSaved = false;
    data.formData.customer = this.customer.id;

    this.cannotSave = false;
    if (
      data.formData.project === null &&
      data.formData.permissionType === 'create'
    ) {
      data.formData.permissionType = 'view';
    }

    try {
      await this.userService.inviteMultiple(data.projectUser, data.formData);
    } catch (e) {
      this.cannotSave = true;
    }

    if (!this.cannotSave) {
      this.inviteSaved = true;
    }

    this.loadUsers();
  }

  public async update(data: { projectUser: ProjectUser; formData: any }) {
    const { permission } = this.manageUserService.extractPrefixAndValue(
      data.formData.permissionType
    );
    data.formData.permissionType = permission;

    this.cannotSave = false;
    this.editSaved = false;

    try {
      this.handleCustomerData(data.formData);
      await this.handleProjectUpdate(data);
    } catch (error) {
      console.error(error);
    } finally {
      this.formLoading = false;
    }
  }

  private handleCustomerData(formData: any) {
    formData.customer = this.customer.id;

    if (formData.project.length === 0 && formData.permissionType === 'create') {
      formData.permissionType = 'view';
    }
  }

  private async handleProjectUpdate(data: {
    projectUser: ProjectUser;
    formData: any;
  }) {
    try {
      if (data.formData.project.length === 0) {
        await this.handleProjectUserWithNoProject(data);
      } else {
        await this.handleProjectUserWithProjects(data);
      }
    } catch (e) {
      this.cannotSave = true;
    }

    if (!this.cannotSave) {
      this.editSaved = true;
    }

    this.loadUsers();
  }

  private async handleProjectUserWithNoProject(data: {
    projectUser: ProjectUser;
    formData: any;
  }) {
    data.formData.project = null;
    const permission = SecurityVoter.findCustomerPermission(
      data.projectUser,
      this.customer
    );

    if (permission === undefined) {
      await this.userService.invite(data.projectUser, data.formData);
    } else {
      await this.userService.updateInvite(permission.id, data.formData);
    }
  }

  private async handleProjectUserWithProjects(data: {
    projectUser: ProjectUser;
    formData: any;
  }) {
    if (data.formData.permissionType === 'edit') {
      data.formData.project = null;
    }

    await this.userService.updateInviteMultiple(
      data.projectUser,
      data.formData
    );
  }

  public async delete(projectUser: ProjectUser) {
    this.deleted = false;
    await this.userService.deletePermissions(projectUser, this.customer);
    this.loadUsers();
    this.deleted = true;
  }

  public changeCustomer(customer: Customer) {
    this.customer = customer;
    this.loadUsers();
  }
}
