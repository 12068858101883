import {
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Project } from '../../../../interfaces/project';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
  NgForm,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ErrorService } from '../../../../services/error.service';
import { ProjectDataBusService } from '../../../../services/project-data-bus.service';
import { ModalComponent } from '../../../../components/modal.component';
import { ProjectService } from '../../../../services/project.service';
import { SurveyService } from 'src/app/services/survey.service';
import { Survey } from 'src/app/interfaces/survey';
import { Sortable } from '../../../../enums/sortable';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SurveyStatus } from 'src/app/enums/survey-status';
import { SurveyQuestionType } from 'src/app/enums/survey-question-type';
import { SurveyQuestion } from 'src/app/interfaces/survey-question';
import { SurveyQuestionOption } from 'src/app/interfaces/survey-question-option';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { QuillComponent } from '../../../../components/quill.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { AccessDirective } from '../../../../directives/access.directive';
import { NgIf, NgClass, NgFor, NgStyle, DatePipe } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { platform } from '../../../../services/platform.service';
import { ThemeService } from 'src/app/services/theme.service';

const CustomRangeValidator: ValidatorFn = (fg: FormGroup) => {
  const start = fg.get('ratingStart');
  const end = fg.get('ratingEnd');
  const type = fg.get('type').value;

  if (type !== SurveyQuestionType.RANGE) {
    return null;
  }

  if (start && end && Number(start.value) < Number(end.value)) {
    start.setErrors(null);
    end.setErrors(null);
    return null;
  }
  const error = { range: true };
  start.setErrors(error);
  end.setErrors(error);
  return error;
};

@Component({
  selector: 'app-default-projects-detail-feedback-edit',
  templateUrl: 'edit.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    RouterLink,
    FormChangeDetectorDirective,
    AccessDirective,
    LoadingDirective,
    ClipboardModule,
    ReactiveFormsModule,
    FormGroupComponent,
    QuillComponent,
    NgFor,
    InlineSVGModule,
    BaseChartDirective,
    NgStyle,
    ModalComponent,
    DragulaModule,
    ConfirmDeleteComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class DetailFeedbackEditComponent {
  project: Project;
  edit = false;
  loading = false;
  survey: Survey;
  Sortable = Sortable;
  surveyForm: FormGroup;
  sortList: SurveyQuestion[];
  surveyTab = 'editor';
  SurveyStatus = SurveyStatus;
  SurveyQuestionType = SurveyQuestionType;
  statisticQuestions: SurveyQuestion[];
  logo: SafeResourceUrl;
  private id: number;
  copied: boolean = false;
  pushSent: string;
  chartSettings = {
    colors: {
      backgroundColor: '#F5B049',
      borderColor: '#F5B049',
      hoverBackgroundColor: '#F5B049',
    },
    ticks: {
      min: 0,
      fontStyle: 'bold',
      fontColor: '#000',
      fontFamily: "'Catamaran', sans-serif",
      fontSize: 16,
    },
    gridLines: false,
  };
  @ViewChildren('question') questionElements: QueryList<ElementRef>;
  @ViewChild('sortModal', { static: true }) private sortModal: ModalComponent;
  @ViewChild('deactivateModal', { static: true })
  private deactivateModal: ModalComponent;
  @ViewChild('formRef') formRef: NgForm;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private surveyService: SurveyService,
    private projectService: ProjectService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dragulaService: DragulaService,
    private sanitizer: DomSanitizer,
    private themeSerivce: ThemeService,
    private elementRef: ElementRef
  ) {
    this.createSurveyForm();
    this.logo = sanitizer.bypassSecurityTrustResourceUrl(platform.logo);
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );
    this.projectDataBusService.emitIsConcept(true);

    this.dragulaService.createGroup(Sortable.SURVEYS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('draggable') ||
          handle.parentElement.classList.contains('draggable') ||
          handle.parentElement.parentElement.classList.contains('draggable')
        );
      },
    });

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.id = params['id'];
        this.edit = true;
        this.loadSurvey(this.id);
        this.loadStatistics(this.id);
      } else {
        this.id = null;
        this.edit = false;
        this.addQuestion(); //add empty question to start with
      }
    });
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['tab']) this.surveyTab = queryParams['tab'];
    });

    window.scroll(0, 0);
  }

  get surveyQuestions() {
    return this.surveyForm.get('surveyQuestions') as FormArray;
  }

  async ngOnInit() {
    const version = await this.themeSerivce.getVersion();

    if (version === 2) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
        platform.logo.replace('.png', '_beta.png')
      );
    }
  }

  exportCSV() {
    this.surveyService
      .getExportCSV(this.survey)
      .then((result) => {
        const blob = new Blob([result], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSurveyURL() {
    return (
      location.protocol +
      '//' +
      this.project.pwaDomain +
      '/projects/' +
      this.project.slug +
      '/surveys'
    );
  }

  private static setLabels(chartInstance, data, barType, question) {
    chartInstance.chart.ctx.textAlign = 'center';
    chartInstance.chart.ctx.textBaseline = 'middle';
    chartInstance.chart.ctx.fillStyle = '#A3A3A3';
    data.datasets.forEach((dataset, i) => {
      let meta = chartInstance.chart.getDatasetMeta(i);
      meta.data.forEach(function (bar, index) {
        let positionX = barType == 'bar' ? bar.x : bar.x + 35;
        let positionY = barType == 'bar' ? bar.y - 15 : bar.y;
        let str = barType == 'bar' ? dataset.data[index] + ' \u2022 ' : '';
        if (question.statistics.totalAnswers > 0)
          str +=
            (
              (dataset.data[index] / question.statistics.totalAnswers) *
              100
            ).toFixed(1) + '%';
        else str += '0%';
        chartInstance.chart.ctx.fillText(str, positionX, positionY);
      });
    });
  }

  getChartOptions(highestCount, barType, question) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1000,
        onProgress: function (chart) {
          DetailFeedbackEditComponent.setLabels(
            chart,
            this.data,
            barType,
            question
          );
        },
        onComplete: function (chart) {
          DetailFeedbackEditComponent.setLabels(
            chart,
            this.data,
            barType,
            question
          );
        },
      },
      indexAxis: barType == 'horizontalBar' ? 'y' : 'x',
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
      hover: { mode: null },
      scales: {
        y: {
          ticks: {
            max: highestCount + 1,
            ...this.chartSettings.ticks,
          },
          display: barType == 'horizontalBar' ? true : false,
          grid: {
            display: this.chartSettings.gridLines,
          },
        },
        x: {
          ticks: {
            max: highestCount + 1,
            ...this.chartSettings.ticks,
          },
          display: barType == 'bar' ? true : false,
          grid: {
            display: this.chartSettings.gridLines,
          },
        },
      },
      layout: {
        padding: {
          right: 80,
          top: 40,
        },
      },
    } as ChartOptions;
  }

  loadStatistics(id) {
    this.surveyService.getStatistics(id).then((questions: SurveyQuestion[]) => {
      questions.forEach((question: SurveyQuestion, index) => {
        if (
          question.type == SurveyQuestionType.CHECKBOX ||
          question.type == SurveyQuestionType.MULTIPLE_CHOICE ||
          question.type == SurveyQuestionType.RANGE
        ) {
          let barChartLabels = [];
          let barChartDataSets: ChartDataset[] = [];
          if (question.statistics?.options) {
            let data = [];
            let barType =
              question.type == SurveyQuestionType.MULTIPLE_CHOICE
                ? 'horizontalBar'
                : 'bar';
            let highestCount = 0;
            for (const key in question.statistics.options) {
              //statistics is an associative array
              let label = (
                question.statistics.options[key].option.title ||
                question.statistics.options[key].option
              ).toString();
              var strArr = label
                .toString()
                .match(/\b[\w']+(?:[^\w\n]+[\w']+){0,2}\b/g);
              strArr.splice(3, strArr.length - 3);
              if (barType == 'horizontalBar')
                strArr[strArr.length - 1] +=
                  ' (' + question.statistics.options[key].count + ')';
              barChartLabels.push(strArr);
              let count = question.statistics.options[key].count;
              data.push(count);
              if (count > highestCount) highestCount = count;
            }
            let barChartData: ChartDataset = {
              data: data,
              ...this.chartSettings.colors,
              barPercentage: barType == 'bar' ? 0.5 : 0.2,
            };
            barChartDataSets.push(barChartData);
            question['barChartType'] = 'bar';
            question['barChartOptions'] = this.getChartOptions(
              highestCount,
              barType,
              question
            );
            question['barChartLabels'] = barChartLabels;
            question['barChartData'] = barChartDataSets;
          }
        }
      });
      this.statisticQuestions = questions;
    });
  }

  addQuestion(
    question: SurveyQuestion = null,
    scrollToView: boolean = false,
    index: number = null
  ) {
    const questionForm = this.formBuilder.group(
      {
        ['id']: [],
        title: ['', [Validators.required, Validators.maxLength(250)]],
        type: ['', Validators.required],
        description: [],
        withDescription: [],
        required: [0],
        sortOrder: [this.surveyQuestions.length || 0],
        surveyQuestionOptions: this.formBuilder.array([]),
        ratingStart: ['1'],
        ratingEnd: ['10'],
        withEmoji: [{ value: false, disabled: !question }],
      },
      { validator: CustomRangeValidator }
    );

    questionForm.get('type').valueChanges.subscribe((value) => {
      questionForm.get('required').enable();
      (<FormArray>questionForm.get('surveyQuestionOptions')).clear();
      if (
        value == SurveyQuestionType.MULTIPLE_CHOICE ||
        value == SurveyQuestionType.CHECKBOX
      ) {
        this.addOption(questionForm);
      } else if (value == SurveyQuestionType.DESCRIPTION) {
        questionForm.get('withDescription').patchValue(true);
        questionForm.get('required').patchValue(false);
        questionForm.get('required').disable();
      } else if (value == SurveyQuestionType.RANGE) {
        if (!questionForm.get('ratingStart').value)
          questionForm.get('ratingStart').patchValue(1);
        if (!questionForm.get('ratingEnd').value)
          questionForm.get('ratingEnd').patchValue(10);
      }
    });

    questionForm.get('ratingStart').valueChanges.subscribe((value) => {
      if (
        value == 1 &&
        [2, 3, 4, 5].indexOf(parseInt(questionForm.get('ratingEnd').value)) > -1
      ) {
        questionForm.get('withEmoji').enable();
      } else {
        questionForm.get('withEmoji').patchValue(false);
        questionForm.get('withEmoji').disable();
      }
    });

    questionForm.get('ratingEnd').valueChanges.subscribe((value) => {
      if (
        [2, 3, 4, 5].indexOf(parseInt(value)) > -1 &&
        questionForm.get('ratingStart').value == 1
      ) {
        questionForm.get('withEmoji').enable();
      } else {
        questionForm.get('withEmoji').patchValue(false);
        questionForm.get('withEmoji').disable();
      }
    });

    if (question) {
      questionForm.patchValue(question, { emitEvent: false });
      if (
        question.description ||
        question.type == SurveyQuestionType.DESCRIPTION
      )
        questionForm
          .get('withDescription')
          .patchValue(true, { emitEvent: false });
    }

    if (index !== null) {
      questionForm.get('sortOrder').patchValue(index);
      this.surveyQuestions.insert(index, questionForm);
      this.surveyQuestions.controls.forEach((question: FormGroup, _index) => {
        if (_index > index)
          question
            .get('sortOrder')
            .patchValue(question.get('sortOrder').value + 1);
      });
    } else this.surveyQuestions.push(questionForm);

    if (scrollToView) {
      setTimeout(() => {
        //add a timeout to wait for the element to be added to the DOM
        this.questionElements
          .toArray()
          [
            index !== null ? index : this.questionElements.length - 1
          ].nativeElement.scrollIntoView();
        this.questionElements
          .toArray()
          [
            index !== null ? index : this.questionElements.length - 1
          ].nativeElement.querySelector('input')
          .focus();
      });
    }

    return questionForm;
  }

  async sendPush() {
    try {
      await this.surveyService.sendPush(this.survey);
      this.pushSent = 'success';
    } catch (error) {
      this.pushSent = 'fail';
    }

    setTimeout(() => {
      this.pushSent = null;
    }, 3000);
  }

  onKeyDown(event, questionForm, questionIndex, optionIndex) {
    if (event.key === 'Enter') {
      this.addOption(questionForm, null, true, questionIndex, optionIndex + 1);
    }
  }

  textCopied() {
    this.copied = true;
    setTimeout(() => (this.copied = false), 2000);
  }

  getValue(value) {
    return value ? 1 : 0;
  }

  async removeQuestion(index: number) {
    let question: SurveyQuestion = this.surveyQuestions.controls[index].value;
    if (question.id) {
      await this.surveyService.removeQuestion(question);
    }
    this.surveyQuestions.removeAt(index);
    if (this.surveyQuestions.length == 0) this.addQuestion();
    else {
      this.surveyQuestions.controls.forEach((question: FormGroup, _index) => {
        if (_index >= index)
          question
            .get('sortOrder')
            .patchValue(question.get('sortOrder').value - 1);
      });
    }
  }

  getOptions(questionForm: FormGroup) {
    return (<FormGroup>questionForm.get('surveyQuestionOptions')).controls;
  }

  addOption(
    questionForm: FormGroup,
    option = null,
    scrollToView: boolean = false,
    questionIndex: number = null,
    optionIndex: number = null
  ) {
    const optionForm = this.formBuilder.group({
      ['id']: [],
      title: ['', [Validators.required, Validators.maxLength(250)]],
      action: [null],
    });
    if (option) {
      if (option.action instanceof Array) option.action = option.action[0]; //until we support multiple actions per option, we have to take the first one now
      optionForm.patchValue(option);
    }

    if (optionIndex !== null)
      (questionForm.get('surveyQuestionOptions') as FormArray).insert(
        optionIndex,
        optionForm
      );
    else
      (questionForm.get('surveyQuestionOptions') as FormArray).push(optionForm);

    if (scrollToView) {
      setTimeout(() => {
        //add a timeout to wait for the element to be added to the DOM
        let focusIndex =
          optionIndex !== null
            ? optionIndex
            : (<FormArray>questionForm.get('surveyQuestionOptions')).length - 1;
        this.questionElements
          .toArray()
          [questionIndex].nativeElement.querySelector(
            '#option-' + questionIndex + '-' + focusIndex
          )
          .focus();
      });
    }
  }

  async removeOption(event: any) {
    let questionForm: FormGroup = event.questionForm;
    let index: number = event.index;
    let options = <FormArray>questionForm.get('surveyQuestionOptions');
    let option = options.controls[index].value;
    if (option.id) {
      await this.surveyService.removeOption(option);
    }
    options.removeAt(index);
    if (options.length == 0) this.addOption(questionForm);
  }

  createSurveyForm() {
    this.surveyForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(250)]],
      description: [''],
      status: [SurveyStatus.INACTIVE, Validators.required],
      surveyQuestions: this.formBuilder.array([]),
    });
  }

  async loadSurvey(id: number): Promise<void> {
    try {
      this.survey = await this.surveyService.fetch(id);
      this.surveyForm.patchValue(this.survey);
      if (this.survey.surveyQuestions.length > 0) {
        this.survey.surveyQuestions.forEach(
          (question: SurveyQuestion, index) => {
            const questionForm = this.addQuestion(question);
            if (question.surveyQuestionOptions) {
              question.surveyQuestionOptions.forEach(
                (option: SurveyQuestionOption) => {
                  this.addOption(questionForm, option);
                }
              );
            } else {
              this.addOption(questionForm);
            }
          }
        );
      } else {
        this.addQuestion(); //add empty question to start with
      }
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  async saveSurvey(status: string) {
    this.formRef.ngSubmit.emit();
    this.surveyForm.get('status').patchValue(status);
    this.errorService.markFormGroupTouchedAndDirty(this.surveyForm);

    this.surveyQuestions.controls.forEach((questionForm: FormGroup) => {
      if (questionForm.get('type').value != SurveyQuestionType.RANGE) {
        questionForm.get('ratingStart').patchValue(null);
        questionForm.get('ratingEnd').patchValue(null);
        questionForm.get('withEmoji').patchValue(null);
      }
    });

    if (this.surveyForm.valid) {
      this.loading = true;
      let data = this.surveyForm.getRawValue() as Survey;
      data.surveyQuestions.forEach((question) => {
        if (question.surveyQuestionOptions.length) {
          question.surveyQuestionOptions.forEach((option) => {
            option.action = [option.action]; //until we support multiple select, we have to transform the action string into an object
          });
        }
      });

      try {
        if (this.edit) {
          data.id = this.id;
          const survey: Survey = await this.surveyService.update(data);
        } else {
          const survey: Survey = await this.surveyService.create(
            this.project,
            data
          );
        }
        this.projectDataBusService.emitProject(
          await this.projectService.fetch(this.project.slug)
        );
        this.router.navigateByUrl(
          '/projects/' + this.project.slug + '/feedback'
        );
      } catch (error) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.errorService.parseErrorsToForm(this.surveyForm, error.error);
        this.loading = false;
      }
    } else {
      const invalidControls: HTMLElement[] =
        this.elementRef.nativeElement.querySelectorAll('.ng-invalid');
      invalidControls[invalidControls.length - 1].scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  getFeedbackStatusClass(): string {
    return 'green';
  }

  goToQuestion(event) {
    this.questionElements
      .toArray()
      [event.target.value].nativeElement.scrollIntoView();
    event.target.value = '';
  }

  moveDown(question, index) {
    this.sortList.splice(index, 1);
    this.sortList.splice(index + 1, 0, question);
  }

  moveUp(question, index) {
    this.sortList.splice(index, 1);
    this.sortList.splice(index - 1, 0, question);
  }

  openSortModal() {
    this.sortList = this.surveyQuestions.getRawValue();
    this.sortModal.open();
  }

  async saveSort() {
    this.surveyQuestions.patchValue(this.sortList);
    this.surveyQuestions.controls.forEach((question: FormGroup, index) => {
      question.get('sortOrder').patchValue(index);
      (<FormArray>question.get('surveyQuestionOptions')).clear(); //reset the options
      this.sortList[index].surveyQuestionOptions.forEach((option) => {
        let action = option.action?.split(':')[1];
        if (action && action != 'additional') {
          if (action <= index) option.action = null; //remove action if it can't point to a question anymore
        }
        this.addOption(question, option);
      });
    });
    this.sortModal.close();
  }

  async deactivateSurvey() {
    this.saveSurvey(SurveyStatus.INACTIVE);
    this.deactivateModal.close();
  }

  ngOnDestroy() {
    this.projectDataBusService.emitIsConcept(false);
    this.dragulaService.destroy(Sortable.SURVEYS);
  }
}
