<app-success-message [onScreen]="true" [hidden]="!formSaved">
  {{ 'profile.personal.success' | translate }}
</app-success-message>

<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1 *appVersion="1">{{ 'profile.personal.title' | translate }}</h1>
      <h1 *appVersion="2">{{ 'profile.personal.edit' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <button
        form="personalForm"
        type="submit"
        class="primary"
        [appLoading]="loading || uploading"
      >
        {{ 'profile.personal.save' | translate }}
      </button>
    </div>
  </div>

  <app-loader *ngIf="!projectUser"></app-loader>

  <form
    *ngIf="projectUser"
    id="personalForm"
    appFormChangeDetector
    [formGroup]="form"
    class="bouwapp-group"
    (submit)="saveGeneral()"
  >
    <div class="bouwapp-group__header">
      <h2 *appVersion="1">{{ 'profile.personal.title' | translate }}</h2>
      <h2 *appVersion="2">{{ 'profile.personal.information' | translate }}</h2>
    </div>

    <ng-container *appVersion="1">
      <div class="bouwapp-group__row two">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="firstName">
            <label for="firstName">{{
              'profile.personal.first_name' | translate
            }}</label>
            <input type="text" id="firstName" formControlName="firstName" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="lastName">
            <label for="lastName">{{
              'profile.personal.last_name' | translate
            }}</label>
            <input type="text" id="lastName" formControlName="lastName" />
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row two">
        <div class="bouwapp-group__item">
          <app-form-group>
            <label for="email">{{
              'profile.personal.email' | translate
            }}</label>
            <input type="text" id="email" formControlName="email" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="phoneNumber">
            <label for="phoneNumber">{{
              'profile.personal.phone' | translate
            }}</label>
            <angular-intl-phone
              formControlName="phoneNumber"
              [config]="phoneConfig"
            ></angular-intl-phone>
            <div class="two-fa-link">
              <a routerLink="/two-factor">{{
                'profile.personal.two_fa_link' | translate
              }}</a>
            </div>
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row two">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="avatar">
            <label>{{ 'profile.personal.profile_picture' | translate }}</label>
            <app-input-file
              type="project-user-avatar"
              [uploadImages]="true"
              formControlName="avatar"
              [rotateWarning]="false"
              (startUploading)="startUploading()"
              (stopUploading)="stopUploading()"
              (previewUpdated)="preview.update($event)"
            ></app-input-file>
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group>
            <label>{{
              'profile.personal.profile_picture.preview' | translate
            }}</label>
            <app-input-file-preview
              #preview
              [thumbnails]="projectUser?.avatarThumbnails"
              [deleteText]="
                'profile.personal.profile_picture.delete' | translate
              "
              formControlName="avatar"
            ></app-input-file-preview>
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row two">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="avatar">
            <label>{{ 'profile.personal.beta.enable' | translate }}</label>
            <select formControlName="beta">
              <option [ngValue]="false">
                {{ 'profile.personal.beta.no' | translate }}
              </option>
              <option [ngValue]="true">
                {{ 'profile.personal.beta.yes' | translate }}
              </option>
            </select>
            <p class="note">{{ 'profile.personal.beta.note' | translate }}</p>
          </app-form-group>
        </div>
      </div>
    </ng-container>

    <ng-container *appVersion="2">
      <div class="bouwapp-group__row two">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="avatar">
            <label *appVersion="1">{{
              'profile.personal.profile_picture' | translate
            }}</label>
            <label *appVersion="2">{{
              'profile.personal.avatar' | translate
            }}</label>
            <div
              class="bouwapp-group__item__media"
              [style.backgroundImage]="
                avatarPreview ? 'url(' + avatarPreview + ')' : null
              "
            >
              <app-input-file-v2
                type="project-user-avatar"
                [selected]="avatarPreview"
                [standalone]="true"
                [uploadImages]="true"
                (uploadStart)="addAvatar($event)"
                (uploadFinished)="updateAvatar($event)"
                (uploadFailed)="uploadError($event)"
                (fileRemoved)="removeAvatar($event)"
              ></app-input-file-v2>
            </div>
            <div class="error" *ngIf="errorMessage">
              <span>{{ errorMessage }}</span>
            </div>
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row three">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="firstName">
            <label for="firstName">{{
              'profile.personal.first_name' | translate
            }}</label>
            <input type="text" id="firstName" formControlName="firstName" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="lastName">
            <label for="lastName">{{
              'profile.personal.last_name' | translate
            }}</label>
            <input type="text" id="lastName" formControlName="lastName" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="phoneNumber">
            <label for="phoneNumber">
              {{ 'profile.personal.phone' | translate }}
              <a routerLink="/two-factor">{{
                'profile.personal.phone.change' | translate
              }}</a>
            </label>
            <angular-intl-phone
              formControlName="phoneNumber"
              [config]="phoneConfig"
            ></angular-intl-phone>
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row three">
        <div class="bouwapp-group__item">
          <app-form-group>
            <label for="email">{{
              'profile.personal.email' | translate
            }}</label>
            <input type="text" id="email" formControlName="email" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="avatar">
            <label>
              {{ 'profile.personal.beta.enable' | translate }}
              <span
                class="info"
                (click)="modal.open()"
                inlineSVG="/assets/v2/img/icons/info.svg"
              ></span>
            </label>
            <select formControlName="beta">
              <option [ngValue]="false">
                {{ 'profile.personal.beta.no' | translate }}
              </option>
              <option [ngValue]="true">
                {{ 'profile.personal.beta.yes' | translate }}
              </option>
            </select>
          </app-form-group>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<app-modal #modal [title]="'profile.personal.beta.enable' | translate">
  <div
    class="modal-description"
    [innerHTML]="'profile.personal.beta.note' | translate"
  ></div>
</app-modal>
