<div class="article-detail resident-letters">
  <div class="container">
    <div class="article-detail__inner">
      <div class="article-detail__head">
        <h1>{{ 'header.menu.resident-letters' | translate }}</h1>
        <div class="article-detail__image">
          <img src="/assets/img/logo_bouwapp.png" />
          <img src="/assets/img/logo_bewonersbrieven.png" />
        </div>
      </div>

      <div class="article-detail__content">
        <h3>{{ 'resident-letters.welcome.title' | translate }}</h3>
        <p>{{ 'resident-letters.welcome.desc' | translate }}</p>
        <h3>{{ 'resident-letters.about-us.title' | translate }}</h3>
        <p>{{ 'resident-letters.about-us.desc' | translate }}</p>
        <h3>{{ 'resident-letters.for-you.title' | translate }}</h3>
        <p>{{ 'resident-letters.for-you.desc' | translate }}</p>
        <a class="btn" href="https://bewonersbrief.nl" target="_blank">
          {{ 'resident-letters.discover.title' | translate }}
        </a>
        <p>{{ 'resident-letters.discover.desc' | translate }}</p>
      </div>
    </div>
  </div>
</div>
