<div [hidden]="isLoading" class="ai-tool">
  <div class="ai-tool__top">
    <p [innerHTML]="'ai-text.description' | translate"></p>
  </div>
  <div class="ai-tool__content">
    <button
      class="button--black-white"
      [tooltip]="
        textLength < 50 ? ('projects.detail.update.tooltip' | translate) : null
      "
      (click)="performAction(AiTextComponent.Action.IMPROVE_TEXT)"
      [class.inactive]="textLength < 50"
      [disabled]="
        textLength < 50 ||
        getActiveAction() === AiTextComponent.Action.IMPROVE_TEXT
      "
    >
      <span
        class="magic-wand"
        inlineSVG="/assets/img/icons/magic-wand.svg"
      ></span>
      <p>{{ 'ai-text.action.improve-text' | translate }}</p>
    </button>

    <button
      class="button--black-white"
      [tooltip]="
        textLength < 50 ? ('projects.detail.update.tooltip' | translate) : null
      "
      (click)="performAction(AiTextComponent.Action.IMPROVE_GRAMMAR)"
      [class.inactive]="textLength < 50"
      [disabled]="
        textLength < 50 ||
        getActiveAction() === AiTextComponent.Action.IMPROVE_GRAMMAR
      "
    >
      <span
        class="spellcheck"
        inlineSVG="/assets/img/icons/spellcheck.svg"
      ></span>
      <p>{{ 'ai-text.action.improve-grammar' | translate }}</p>
    </button>

    <div
      class="dropdown"
      [class.inactive]="textLength < 50"
      [class.disabled]="
        textLength < 50 ||
        getActiveAction() === AiTextComponent.Action.REWRITE_LANGUAGE_LEVEL
      "
    >
      <button
        [tooltip]="
          textLength < 50
            ? ('projects.detail.update.tooltip' | translate)
            : null
        "
        class="dropdown__button button--black-white"
        (click)="performAction(AiTextComponent.Action.REWRITE_LANGUAGE_LEVEL)"
        [class.inactive]="textLength < 50"
        [disabled]="
          textLength < 50 ||
          getActiveAction() === AiTextComponent.Action.REWRITE_LANGUAGE_LEVEL
        "
      >
        <span class="stylus" inlineSVG="/assets/img/icons/stylus.svg"></span>
        <p>
          {{ 'ai-text.action.rewrite-language-level' | translate }}
          {{ languageLevelForm.get('selectedLanguageLevel').value }}
        </p>
        <span
          class="caret"
          inlineSVG="/assets/img/icons/caret-down-dark.svg"
        ></span>
      </button>

      <div class="dropdown__content">
        <ul>
          <li
            (click)="selectLanguageLevel(level)"
            *ngFor="let level of Object.keys(languageLevels)"
          >
            <p>
              {{
                languageLevelForm.controls.selectedLanguageLevel.value === level
                  ? level
                  : languageLevels[level] === ''
                  ? level
                  : level + ' (' + (languageLevels[level] | translate) + ')'
              }}
            </p>
          </li>
        </ul>
      </div>
    </div>

    <button
      class="button--black-white"
      [tooltip]="
        textLength < 50 ? ('projects.detail.update.tooltip' | translate) : null
      "
      (click)="performAction(AiTextComponent.Action.ADD_TITLE)"
      [class.inactive]="textLength < 50"
      [disabled]="
        textLength < 50 ||
        getActiveAction() === AiTextComponent.Action.ADD_TITLE
      "
    >
      <span class="add" inlineSVG="/assets/img/icons/add.svg"></span>
      <p>{{ 'ai-text.action.add-title' | translate }}</p>
    </button>

    <button
      (click)="back()"
      class="back button--black-white"
      [class.inactive]="textLength < 50"
      [disabled]="activeActionStack.length === 0"
    >
      <span class="undo" inlineSVG="/assets/img/icons/undo.svg"></span>
      <p>{{ 'ai-text.back' | translate }}</p>
    </button>
  </div>
  <span
    [hidden]="!showReportText"
    [innerHTML]="'ai-text.report' | translate"
  ></span>
  <p>
    {{ 'ai-text.language-text' | translate }}
    <b>{{ getLanguageNameByLocale() | translate }}</b
    >&nbsp;
    <ng-container
      >(<a (click)="openProjectSettings()">{{
        'ai-text.language-change' | translate
      }}</a
      >)
    </ng-container>
  </p>
</div>

<div
  class="ai-loading"
  [hidden]="!isLoading"
  [innerHTML]="
    'ai-text.loading'
      | translate
        : {
            action:
              (getActiveAction() | translate) +
              (getActiveAction() ===
              AiTextComponent.Action.REWRITE_LANGUAGE_LEVEL
                ? ' ' +
                  this.languageLevelForm.controls.selectedLanguageLevel.value
                : '')
          }
  "
></div>
