<div class="container">
  <app-success-message [onScreen]="true" [hidden]="!formSaved">
    {{ 'profile.password.success' | translate }}
  </app-success-message>

  <ng-container *appVersion="1">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'profile.password.title' | translate }}</h1>
        <p>{{ 'profile.password.requirements' | translate }}</p>
        <ul>
          <li>{{ 'profile.password.characters' | translate }}</li>
          <li>
            {{ 'profile.password.groups' | translate }}
            <ul>
              <li>{{ 'profile.password.groups.lowercase' | translate }}</li>
              <li>
                {{ 'profile.password.groups.capital_letters' | translate }}
              </li>
              <li>{{ 'profile.password.groups.numbers' | translate }}</li>
              <li>
                {{ 'profile.password.groups.non_alphanumeric' | translate }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="bouwapp-header__buttons">
        <button
          (click)="changePassword()"
          type="submit"
          class="primary"
          [appLoading]="loading"
        >
          {{ 'profile.password.save' | translate }}
        </button>
      </div>
    </div>

    <form
      appFormChangeDetector
      class="bouwapp-group"
      [formGroup]="form"
      (submit)="changePassword()"
    >
      <div class="bouwapp-group__header">
        <h2>{{ 'profile.password.title' | translate }}</h2>
      </div>

      <div class="bouwapp-group__row">
        <div class="bouwapp-group__item">
          <app-form-group
            [form]="form"
            errorPath="currentPassword"
            *ngIf="!new"
          >
            <label for="currentPassword">{{
              'profile.password.current' | translate
            }}</label>
            <input
              type="password"
              id="currentPassword"
              formControlName="currentPassword"
            />
          </app-form-group>
        </div>

        <app-form-group
          formGroupName="plainPassword"
          [form]="form"
          errorPath="plainPassword"
        >
          <div class="bouwapp-group__item">
            <app-form-group [form]="form" errorPath="plainPassword.first">
              <label for="passwordFirst">{{
                'profile.password.new' | translate
              }}</label>
              <input
                type="password"
                id="passwordFirst"
                formControlName="first"
              />
            </app-form-group>
          </div>
          <div class="bouwapp-group__item">
            <app-form-group [form]="form" errorPath="plainPassword.second">
              <label for="passwordSecond">{{
                'profile.password.new.repeat' | translate
              }}</label>
              <input
                type="password"
                id="passwordSecond"
                formControlName="second"
              />
            </app-form-group>
          </div>
        </app-form-group>
      </div>
    </form>
  </ng-container>

  <ng-container *appVersion="2">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'profile.password.edit' | translate }}</h1>
      </div>
      <div class="bouwapp-header__buttons">
        <button
          (click)="changePassword()"
          type="submit"
          class="primary"
          [appLoading]="loading"
        >
          {{ 'profile.password.save' | translate }}
        </button>
      </div>
    </div>

    <form class="bouwapp-group" [formGroup]="form" (submit)="changePassword()">
      <div class="bouwapp-group__header">
        <h2>{{ 'profile.password.information' | translate }}</h2>
      </div>
      <div class="bouwapp-group__row two password">
        <div class="bouwapp-group__item">
          <app-form-group
            [form]="form"
            errorPath="currentPassword"
            *ngIf="!new"
          >
            <label for="currentPassword">{{
              'profile.password.current' | translate
            }}</label>
            <input
              type="password"
              id="currentPassword"
              formControlName="currentPassword"
            />
          </app-form-group>

          <app-form-group
            formGroupName="plainPassword"
            [form]="form"
            errorPath="plainPassword"
          >
            <app-form-group [form]="form" errorPath="plainPassword.first">
              <label for="passwordFirst">{{
                'profile.password.new' | translate
              }}</label>
              <input
                type="password"
                id="passwordFirst"
                formControlName="first"
              />
            </app-form-group>

            <app-form-group [form]="form" errorPath="plainPassword.second">
              <label for="passwordSecond">{{
                'profile.password.new.repeat' | translate
              }}</label>
              <input
                type="password"
                id="passwordSecond"
                formControlName="second"
              />
            </app-form-group>
          </app-form-group>
        </div>

        <div class="bouwapp-group__item">
          <p>{{ 'profile.password.requirements' | translate }}</p>
          <ul>
            <li [class.valid]="isRequirementMet('minLength')">
              {{ 'profile.password.characters' | translate }}
            </li>
            <li [class.valid]="extraRequirements >= 3">
              {{ 'profile.password.groups' | translate }}
              <ul>
                <li [class.valid]="isRequirementMet('lowercase')">
                  {{ 'profile.password.groups.lowercase' | translate }}
                </li>
                <li [class.valid]="isRequirementMet('uppercase')">
                  {{ 'profile.password.groups.capital_letters' | translate }}
                </li>
                <li [class.valid]="isRequirementMet('number')">
                  {{ 'profile.password.groups.numbers' | translate }}
                </li>
                <li [class.valid]="isRequirementMet('specialChar')">
                  {{ 'profile.password.groups.non_alphanumeric' | translate }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </ng-container>
</div>
