import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import * as moment from 'moment';
import { SafeStyle } from '@angular/platform-browser';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Target } from 'src/app/interfaces/target';
import { Event } from 'src/app/interfaces/event';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AiTextService } from 'src/app/services/ai-text.service';
import * as striptags from 'striptags';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectService } from 'src/app/services/project.service';
import { UpdateService } from 'src/app/services/update.service';
import { ErrorService } from 'src/app/services/error.service';
import { PollChoice } from 'src/app/interfaces/poll-choice';
import { AccessService } from 'src/app/services/access.service';
import { Customer } from 'src/app/interfaces/customer';
import { AccessControlList } from 'src/app/interfaces/access-control-list';
import {
  LatitudeValidator,
  LongitudeValidator,
} from 'src/app/shared/location.validator';
import { TargetPickerComponent } from 'src/app/components/target-picker.component';
import { TargetService } from 'src/app/services/target.service';
import { UpdateCategory } from 'src/app/interfaces/update-category';
import { ErrorMessageComponent } from 'src/app/components/error-message.component';
import { ToggleComponent } from 'src/app/components/toggle.component';
import { MultiAttachmentControlComponent } from 'src/app/components/multi-attachment-control.component';
import { LoadingDirective } from 'src/app/directives/loading.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InputFileComponent } from 'src/app/components/input-file.component';
import { DatePickerComponent } from 'src/app/components/date-picker.component';
import { CharacterCountComponent } from 'src/app/components/character-counter/character-count.component';
import { QuillComponent } from 'src/app/components/quill.component';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { LoaderComponent } from 'src/app/components/loader.component';
import {
  NgIf,
  NgFor,
  NgStyle,
  NgClass,
  DatePipe,
  KeyValuePipe,
} from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateLocationComponent } from 'src/app/components/update-helpers/update-location.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { platform } from '../../../services/platform.service';
import { TwoColumnsLayout } from 'src/app/components/two-columns-layout/two-columns-layout.component';
import { FileUploadModule } from 'ng2-file-upload';

@Component({
  selector: 'app-default-updates-edit',
  templateUrl: 'edit.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    TooltipModule,
    ReactiveFormsModule,
    NgSelectModule,
    UpdateLocationComponent,
    NgClass,
    FormGroupComponent,
    NgFor,
    QuillComponent,
    CharacterCountComponent,
    DatePickerComponent,
    TargetPickerComponent,
    InputFileComponent,
    InlineSVGModule,
    RouterLink,
    LoadingDirective,
    MultiAttachmentControlComponent,
    ToggleComponent,
    NgStyle,
    ErrorMessageComponent,
    DatePipe,
    KeyValuePipe,
    TranslateModule,
    FormChangeDetectorDirective,
    TwoColumnsLayout,
    FileUploadModule,
  ],
})
export class UpdatesEditComponent {
  readonly MAX_POLL_CHOICE = 4;
  readonly MIN_POLL_CHOICE = 2;

  @ViewChild(TargetPickerComponent) targetPicker: TargetPickerComponent;
  @ViewChild(MultiAttachmentControlComponent)
  attachmentComponent: MultiAttachmentControlComponent;

  private id: number;
  private customer: Customer;
  project: Project;
  edit = false;
  updateForm: FormGroup;
  update: Update;
  ProjectStatus: ProjectStatus;
  version: number;
  showTargets: boolean = false;
  customDate: boolean = false;
  private acl: AccessControlList;
  public eventLoading: boolean = false;
  public aiEventEnhancementButtonActive: boolean = false;
  public noEventsFound: boolean = false;
  public isCustomer: boolean = false;

  get pollChoices(): FormArray {
    return this.updateForm.get('poll').get('choices') as FormArray;
  }

  get events(): FormArray {
    return this.updateForm.get('events') as FormArray;
  }

  loading = false;
  hasPush = false;
  pushInPast = false;
  limitMessage = 2000;
  pollChoiceCount = 0;

  uploading = false;
  updateCategories: UpdateCategory[] = [];
  isScheduled: boolean = false;
  totalPush: number = 0;
  targets: Target[];
  saveMode: string;
  originalValue: any;
  saved: boolean = false;
  showEvents: boolean = false;
  showPoll: boolean = false;
  clickedFirst: string;
  publicationDateInPast: boolean = false;
  pushSent: boolean = false;
  initialTime: moment.Moment;
  language: string;
  hasFileOver: boolean;

  private push: boolean = false;
  private concept: boolean = false;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private updateService: UpdateService,
    private errorService: ErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private aiTextService: AiTextService,
    private accessService: AccessService,
    private targetService: TargetService
  ) {
    this.initialTime = moment();
    this.getUpdateCategories();
    this.createUpdateForm();

    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
      this.language = this.project?.language;
      if (null === this.project) {
        this.isCustomer = true;
      }
      if (!this.language) {
        this.language = platform.language;
      }
      const currentRoute = this.router.url;
      if (currentRoute.startsWith('/customers')) {
        this.project = null;
        this.isCustomer = true;
      }
    });

    this.accessService.accessControlList.subscribe((list) => {
      if (list == null) {
        return;
      }
      this.acl = list;
    });

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.id = params['id'];
        this.edit = true;

        this.loadUpdate(this.id);
      } else {
        this.id = null;
        this.edit = false;
      }

      if (this.isCustomer) {
        const slug = params['slug'];
        this.customer = this.accessService
          .getCustomersFromACL(this.acl)
          .find((customer) => customer.slug === slug);
      }
    });

    window.scroll(0, 0);
  }

  async addEventFromAI() {
    this.noEventsFound = false;
    this.eventLoading = true;

    try {
      const results = await this.aiTextService.addEventFromText(
        this.updateForm.get('content').value
      );
      this.eventLoading = false;

      if (results.length === 0) {
        this.noEventsFound = true;
      }

      this.clearEvents();
      results.forEach((result) => {
        result.title = result.title.substring(0, 60);
        this.addEvent(false, result);
      });
    } catch (error) {
      this.noEventsFound = true;
    }

    this.eventLoading = false;
  }

  fileDropped(event: FileList) {
    this.attachmentComponent.fileDropped(event);
    this.hasFileOver = false;
  }

  fileOver(event: boolean) {
    this.hasFileOver = event;
  }

  clearEvents() {
    for (let i = this.events.length - 1; i >= 0; i--) {
      if (!this.events.at(i).touched) {
        this.events.removeAt(i);
      }
    }
    if (this.events.length === 0) {
      this.addEvent(true);
    }
  }

  calculateTotalPush(targets: Target[]) {
    // Special case for all target groups
    // if a custom target group is deleted those devices will still get project wide push notifications
    if (this.updateForm.get('targets').value.length === 0) {
      this.totalPush = this.project.allTargetsDeviceCount;
      const areaTarget = this.targets?.find((value) => value.id === -1);
      if (areaTarget) {
        this.totalPush += areaTarget.devicesCount;
      }
      return;
    }

    if (targets?.length)
      this.totalPush = targets.reduce(
        (total, target) => total + (target.devicesCount || 0),
        0
      );
  }

  setTargets(targets: Target[]) {
    this.targets = targets;
    this.calculateTotalPush(targets);
  }

  toggleShowTargets() {
    this.showTargets = !this.showTargets;
    let originalTargets = Object.assign([], this.originalValue?.targets || []);
    this.updateForm.get('targets').patchValue(originalTargets);
    this.updateForm
      .get('visibleToTargetsOnly')
      .patchValue(this.originalValue?.visibleToTargetsOnly);
    if (!this.showTargets) {
      this.updateForm.get('targets').clearValidators();
      this.updateForm.get('targets').updateValueAndValidity();
    } else {
      this.updateForm.get('targets').setValidators([Validators.required]);
      this.updateForm.get('targets').updateValueAndValidity();
    }
  }

  togglePublicationDate() {
    this.customDate = !this.customDate;

    if (!this.edit && !this.customDate) {
      this.publicationDateInPast = false;
      this.updateForm.get('sendPush').patchValue(true);
      return;
    }

    if (!this.customDate) {
      this.updateForm
        .get('publicationDate')
        .patchValue(this.update.publicationDate);
    }

    this.checkPublicationDateInPast();
    if (!this.publicationDateInPast) {
      this.updateForm.get('sendPush').patchValue(true);
    }
  }

  /**
   * @returns {void}
   */
  private async createUpdateForm() {
    const sendPush = this.formBuilder.control(false, Validators.required);

    this.updateForm = this.formBuilder.group({
      content: ['', Validators.required],
      attachments: [[]],
      sendPush: sendPush,
      targets: [[]],
      locationLat: [null, LatitudeValidator()],
      locationLong: [null, LongitudeValidator()],
      publicationDate: [moment().format()],
      visibleToTargetsOnly: [false],
      areaFollowers: [false],
      status: [ProjectStatus.PUBLISHED],
      category: [null, Validators.required],
    });

    // check if push will be sent immediately
    this.updateForm.valueChanges.subscribe((value) => {
      if (this.customDate) {
        this.checkPublicationDateInPast();
        if (!this.publicationDateInPast) {
          this.updateForm
            .get('sendPush')
            .patchValue(true, { emitEvent: false });
        }
      }
    });

    this.updateForm.get('content').valueChanges.subscribe((value) => {
      if (striptags(value).length >= 50) {
        this.aiEventEnhancementButtonActive = true;
        return;
      }
      this.aiEventEnhancementButtonActive = false;
    });

    this.updateForm.get('targets').valueChanges.subscribe((value) => {
      this.calculateTotalPush(value.length ? value : this.targets);
      if (this.updateForm.get('targets').invalid) {
        this.updateForm.get('targets').setErrors({ targetError: true });
      }
      if (value.some((item: Target) => item.id === -1)) {
        this.updateForm.get('areaFollowers').patchValue(true);
      } else {
        this.updateForm.get('areaFollowers').patchValue(false);
      }
    });

    if (!this.edit) {
      this.updateForm.get('sendPush').patchValue(true);
      let offset = new Date().getTimezoneOffset() * 60000;
      let adjustedDate = new Date(new Date().getTime() - offset);
      this.updateForm
        .get('publicationDate')
        .patchValue(adjustedDate.toISOString().substring(0, 16));
    }

    this.originalValue = this.updateForm.getRawValue();
  }

  private checkPublicationDateInPast() {
    this.publicationDateInPast = this.initialTime.isAfter(
      moment(this.updateForm.get('publicationDate').value)
    );
  }

  setPushAndConcept(push: boolean, concept: boolean = false) {
    this.push = push;
    this.concept = concept;
  }

  /**
   * @Param push
   * @returns {Promise<void>}
   */
  async saveUpdate(): Promise<void> {
    const filesUploading = this.attachmentComponent.attachmentPreviews.some(
      (attachment) => !attachment.error && !attachment.isUploaded
    );
    if (this.uploading || filesUploading) {
      return;
    }

    this.saveMode = this.concept ? 'concept' : 'publish';

    if (this.concept)
      this.updateForm.get('status').patchValue(ProjectStatus.CONCEPT);
    else this.updateForm.get('status').patchValue(ProjectStatus.PUBLISHED);

    const oldPushInPast = this.pushInPast;

    if (this.publicationDateInPast) {
      this.updateForm.get('sendPush').setValue(false);
    }

    this.errorService.markFormGroupTouchedAndDirty(this.updateForm);

    if (this.updateForm.valid) {
      if (
        !oldPushInPast &&
        this.pushInPast &&
        this.update &&
        this.update.sendPush
      ) {
        // show the user a quick warning
        return;
      }

      this.hasPush = this.push;
      this.loading = true;

      try {
        const data = this.updateForm.getRawValue();

        if (!this.customDate) {
          data.publicationDate = this.edit
            ? new Date(this.update.publicationDate).toISOString()
            : new Date().toISOString();
        } else {
          data.publicationDate = new Date(
            this.updateForm.get('publicationDate').value
          ).toISOString();
        }

        if (data.events) {
          data.events.forEach((event) => {
            if (event.startDate)
              event.startDate = new Date(event.startDate).toISOString();
            if (event.endDate)
              event.endDate = new Date(event.endDate).toISOString();
          });
        }

        if (data.attachments) {
          Object.keys(data.attachments).forEach((key) => {
            if (
              !data.attachments[key].filePath &&
              !data.attachments[key].videoId
            ) {
              delete data.attachments[key];
            }
          });
        }

        data.poll = !!data.poll ? data.poll : null;

        if (data.poll) {
          const object = { ...data.poll.choices };

          data.poll.choices = object;

          for (let i in data.poll.choices) {
            if (!data.poll.choices[i].text) {
              delete object[i];
            }
          }

          if (data.poll.choices.length < 2 || !data.poll.title)
            data.poll = null;
        }

        if (data.targets.length > 0) {
          const index = data.targets.findIndex(
            (target: Target) => target.id == -1
          );
          if (index != -1) {
            data.targets.splice(index, 1);
          }
        }

        if (this.edit) {
          data.id = this.id;

          const update: Update = await this.updateService.update(data);
        } else {
          if (this.isCustomer) {
            const update: Update = await this.updateService.create(
              this.customer,
              data
            );
          } else {
            const update: Update = await this.updateService.create(
              this.project,
              data
            );
          }
        }

        this.saved = true;
        if (this.isCustomer) {
          this.router.navigate(['/updates']);
        } else {
          this.router.navigate(['/projects', this.project.slug, 'updates']);
          this.projectService.updateLastEdited(this.project);
        }
      } catch (error) {
        console.log(error);
        this.errorService.parseErrorsToForm(this.updateForm, error.error, {
          pushDateTime: 'publicationDate',
        });
      } finally {
        this.hasPush = false;
        this.loading = false;
      }
    } else {
      await this.errorService.scrollToInvalidFormGroup();
    }
  }

  /**
   * Loads update
   * @param {number} id
   * @returns {Promise<void>}
   */
  async loadUpdate(id: number): Promise<void> {
    try {
      this.update = await this.updateService.fetch(id);
      this.update.publicationDate = new Date(
        this.update.publicationDate
      ).toISOString();
      if (
        this.update.status !== 'CONCEPT' &&
        new Date(this.update.publicationDate) >= new Date()
      )
        this.isScheduled = true;

      this.updateForm.patchValue(this.update);

      const poll = this.update.poll;

      if (poll != null) {
        this.showPoll = true;

        this.addPoll(true);

        for (const choice of poll.choices) {
          this.addPollChoice(choice);
        }

        this.updateForm.patchValue({ poll });
      }

      const events = this.update.events;
      if (events != null && events.length >= 1) {
        this.showEvents = true;

        for (const event of events) {
          this.addEvent(false, event);
        }
      }

      this.originalValue = this.updateForm.getRawValue();

      this.pushSent =
        this.update.pushStatus === 'sending' ||
        this.update.pushStatus === 'sent';
      this.updateForm.get('sendPush').patchValue(!!this.update.pushStatus);

      this.checkPublicationDateInPast();
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  public async handleLocationPicked(data: any) {
    this.updateForm.get('locationLong').patchValue(data[1]);
    this.updateForm.get('locationLat').patchValue(data[0]);

    const devicesCount = await this.targetService.fetchNotificationCount(
      data[0],
      data[1]
    );
    if (undefined === this.targetPicker) {
      this.totalPush = devicesCount;
    } else {
      this.targetPicker.setAreaFollowersCount(devicesCount);
    }
  }

  public addEvent(userEvent: boolean = false, event?: Event) {
    if (!this.showPoll && userEvent) this.clickedFirst = 'events';
    this.showEvents = true;

    if (!this.updateForm.contains('events')) {
      this.updateForm.addControl('events', this.formBuilder.array([]));
    }

    const group = this.formBuilder.group({
      id: [null],
      title: ['', [Validators.required, Validators.maxLength(60)]],
      startDate: [null, Validators.required],
      endDate: [null],
    });

    if (event) {
      let offset = 0;
      offset = new Date().getTimezoneOffset() * 60000;

      let adjustedStartDate = new Date(
        new Date(event.startDate).getTime() - offset
      );
      event.startDate = adjustedStartDate.toISOString().substring(0, 16) as any;

      if (event.endDate) {
        let adjustedEndDate = new Date(
          new Date(event.endDate).getTime() - offset
        );
        event.endDate = adjustedEndDate.toISOString().substring(0, 16) as any;
      }
      group.patchValue(event as any);
    }

    group.valueChanges.subscribe((value) => {
      const startDate = new Date(value.startDate).getTime();
      const endDate = new Date(value.endDate).getTime();
      const currDate = new Date().getTime();
      const days = 24 * 60 * 60 * 1000;

      if (startDate) {
        if (startDate < currDate - days * 30) {
          group.get('startDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_past'
            ),
          });
        } else if (startDate > currDate + days * 365) {
          group.get('startDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_future'
            ),
          });
        } else {
          group.get('startDate').setErrors(null);
        }
      }

      if (endDate) {
        if (endDate <= startDate) {
          group.get('endDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_end'
            ),
          });
        } else if (endDate - startDate > days * 30) {
          group.get('endDate').setErrors({
            invalidDate: true,
            message: this.translateService.instant(
              'form_group.invalid_date_diff'
            ),
          });
        } else {
          group.get('endDate').setErrors(null);
        }
      }
    });

    this.events.push(group);

    setTimeout(() => {
      if (userEvent)
        document
          .getElementById('event' + (this.events.length - 1))
          .scrollIntoView();
    });
  }

  public removeEvent(index: number) {
    if (this.events.length > 1) {
      this.events.removeAt(index);
    } else {
      this.events.at(index).reset();
    }
  }

  public removeAllEvents() {
    this.showEvents = false;
    this.updateForm.removeControl('events');
  }

  public addPoll(noAdd: boolean = false, userEvent: boolean = false) {
    if (!this.showEvents && userEvent) this.clickedFirst = 'poll';
    this.showPoll = true;

    this.updateForm.addControl(
      'poll',
      this.formBuilder.group({
        '@id': [''], // for api platform: keep reference
        '@type': [''], // for api platform: keep reference
        id: [null],
        title: [null, Validators.required],
        choices: this.formBuilder.array([]),
      })
    );

    const title = this.updateForm.get('poll').get('title');
    title.clearValidators();
    title.valueChanges.subscribe((value) => {
      if (value) {
        this.pollChoices.controls.forEach((control) => {
          control.get('text').setValidators([Validators.required]);
          control.get('text').updateValueAndValidity();
        });
      } else {
        this.pollChoices.controls.forEach((control) => {
          control.get('text').clearValidators();
          control.get('text').updateValueAndValidity();
        });
      }
    });

    if (!noAdd) {
      // minimum of two answers
      this.addPollChoice();
      this.addPollChoice();
    }

    this.originalValue = this.updateForm.getRawValue();
  }

  public addPollChoice(value: PollChoice = null) {
    const group = this.formBuilder.group({
      '@id': [''], // for api platform: keep reference
      id: [null],
      text: ['', Validators.required],
      fileName: [''],
      filePath: [null],
      filePathThumbnails: [null],
      preview: [null],
    });

    group.get('text').clearValidators();

    this.pollChoices.push(group);

    if (value != null) {
      group.patchValue(value);
    }

    this.pollChoiceCount++;
  }

  public updatePollChoicePreview(control: AbstractControl, preview: string) {
    control.get('preview').patchValue(preview);
  }

  public getPollPreview(control: AbstractControl): SafeStyle {
    const preview = control.get('preview').value;
    const filePath = control.get('filePath').value;
    const filePathThumbnails = control.get('filePathThumbnails').value;

    const url = preview
      ? preview
      : filePathThumbnails
      ? filePathThumbnails?.small
      : '';

    return 'url(' + url + ')';
  }

  public pollChoiceHasPreview(control: AbstractControl): boolean {
    return (
      !!control.get('preview').value ||
      (control.get('filePathThumbnails').value &&
        Object.keys(control.get('filePathThumbnails').value).length !== 0)
    );
  }

  public removePollChoice(index: number) {
    const choices = this.updateForm.get('poll').get('choices') as FormArray;
    choices.removeAt(index);
    this.pollChoiceCount--;
  }

  public removePoll() {
    this.showPoll = false;

    this.updateForm.removeControl('poll');
    this.pollChoiceCount = 0;
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
  }

  public pollChoiceValue(index: number) {
    return this.updateForm.get('poll').get('choices').value[index].text;
  }

  private getUpdateCategories() {
    this.updateService.listCategories().then((response) => {
      this.updateCategories = response;
    });
  }
}
