import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorService } from '../../../services/error.service';
import { UserDataService } from '../../../services/user-data.service';
import { ProjectUserService } from '../../../services/project-user.service';
import { ProjectUser } from '../../../interfaces/project-user';
import {
  AngularIntlPhoneConfig,
  AngularIntlPhoneModule,
} from 'angular-intl-phone';
import { LocaleService } from 'src/app/services/locale.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../directives/loading.directive';
import { InputFilePreviewComponent } from '../../../components/input-file-preview.component';
import { InputFileComponent } from '../../../components/input-file.component';
import { RouterLink } from '@angular/router';
import { FormGroupComponent } from '../../../components/form-group.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { platform } from '../../../services/platform.service';
import { VersionDirective } from 'src/app/directives/version.directive';
import { ModalComponent } from 'src/app/components/modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InputFileV2Component } from 'src/app/components/input-file-v2.component';

@Component({
  selector: 'app-default-profile-personal',
  templateUrl: 'personal.component.html',
  standalone: true,
  imports: [
    NgIf,
    FormChangeDetectorDirective,
    LoaderComponent,
    SuccessMessageComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    AngularIntlPhoneModule,
    RouterLink,
    InputFileComponent,
    InputFileV2Component,
    InputFilePreviewComponent,
    LoadingDirective,
    TranslateModule,
    VersionDirective,
    ModalComponent,
    InlineSVGModule,
  ],
})
export class PersonalComponent implements OnInit {
  /**
   * @type {FormGroup}
   */
  public form: FormGroup;

  /**
   * @type {boolean}
   */
  public formSaved = false;

  /**
   * @type {ProjectUser}
   */
  public projectUser: ProjectUser;

  /**
   * @type {boolean}
   */
  loading = false;
  uploading = false;
  avatarPreview: string;
  errorMessage: string;

  phoneConfig: AngularIntlPhoneConfig = {
    id: 'phoneNumber',
    name: 'phoneNumber',
    options: {
      separateDialCode: true,
      localizedCountries: {},
      onlyCountries: ['au', 'cz', 'dk', 'de', 'gb', 'nl', 'sk', 'be'],
      preferredCountries: [],
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private userDataService: UserDataService,
    private localeService: LocaleService,
    private projectUserService: ProjectUserService,
    private translateService: TranslateService
  ) {
    this.createForm();
    if (localeService.localizedCountryNames) {
      this.phoneConfig.options.localizedCountries =
        localeService.localizedCountryNames;
    }

    this.translateService
      .get('two.factor.phone.placeholder')
      .subscribe((result) => {
        this.phoneConfig.placeholder = result;
      });
    this.phoneConfig.options.initialCountry =
      platform.language == 'nl' ? 'nl' : 'gb';
  }

  /**
   * @returns {void}
   */
  async loadProjectUser(): Promise<void> {
    const projectUser: ProjectUser =
      await this.userDataService.retrieveProjectUser();
    const email: string = await this.userDataService.retrieveEmail(); // get user email

    // fetch additional details
    this.projectUser = await this.projectUserService.fetch(projectUser.id);
    this.form.patchValue(this.projectUser);
    this.form.get('email').patchValue(email);
    this.avatarPreview = this.projectUser.avatarThumbnails?.small;
  }

  /**
   * @returns {void}
   */
  createForm(): void {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [''], // will be ignored, but has to be showed
      phoneNumber: [''],
      avatar: [''],
      beta: [false],
    });

    this.form.get('phoneNumber').disable();
    this.form.get('email').disable();
  }

  /**
   * @returns {Promise<void>}
   */
  async saveGeneral(): Promise<void> {
    if (this.uploading) {
      return;
    }

    this.formSaved = false;
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      this.loading = true;
      try {
        const data = this.form.getRawValue();

        data.id = this.projectUser.id;
        data.phoneNumber = data.phoneNumber?.e164Number?.replace(/\+/g, '');
        data.twoFactorEnabled = this.projectUser.twoFactorEnabled;

        const projectUser: ProjectUser = await this.projectUserService.update(
          data
        );
        await this.userDataService.storeProjectUser(projectUser);
        this.formSaved = true;
        window.location.reload();
      } catch (error) {
        this.errorService.parseErrorsToForm(this.form, error.error, {
          avatarFile: 'avatar',
        });
      } finally {
        this.loading = false;
      }
    }
  }

  public removeAvatar(event: any) {
    this.form.get('avatar').reset();
    this.avatarPreview = null;
  }

  public uploadError(event: any) {
    this.uploading = false;
    this.errorMessage = event.error;
    this.avatarPreview = null;
  }

  public addAvatar(event: any) {
    this.uploading = true;
    this.errorMessage = null;
    this.avatarPreview = event.preview;
  }

  public updateAvatar(event: any) {
    this.uploading = false;
    this.errorMessage = null;
    this.form.get('avatar').patchValue(event.filePath);
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
  }

  /**
   * @returns {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    await this.loadProjectUser();
    if (this.projectUser.phoneNumber) {
      const phoneNumber = '+' + this.projectUser.phoneNumber;
      this.form.get('phoneNumber').setValue(phoneNumber);
    }
  }
}
