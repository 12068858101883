import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Update } from 'src/app/interfaces/update';
import { UpdateService } from 'src/app/services/update.service';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from 'src/app/components/loader.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from 'src/app/directives/access.directive';
import { UpdatesTileComponent } from './tile.component';
import { ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Map } from 'mapbox-gl';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { StatisticComponent } from 'src/app/components/statistic.component';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete.component';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ModalComponent } from 'src/app/components/modal.component';

@Component({
  selector: 'app-default-updates-view',
  templateUrl: 'view.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UpdatesTileComponent,
    LoaderComponent,
    InlineSVGModule,
    AccessDirective,
    RouterLink,
    TranslateModule,
    BaseChartDirective,
    NgxMapboxGLModule,
    StatisticComponent,
    ConfirmDeleteComponent,
    ModalComponent,
  ],
})
export class UpdatesViewComponent implements OnInit {
  update: Update;
  updateId: number;
  isCustomer: boolean;
  ready = false;

  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;
  modalTitle: string;
  modalDesc: string;

  interactionsChartData: ChartData<'bar'>;
  reactionsChartData: ChartData<'doughnut'>;
  sourcesChartData: ChartData<'doughnut'>;
  devicesChartData: ChartData<'doughnut'>;
  appsChartData: ChartData<'doughnut'>;

  constructor(
    private updateService: UpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private projectDataBusService: ProjectDataBusService,
    private translateService: TranslateService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id != null) {
        this.updateId = params.id;
        this.loadUpdate();
        this.loadStatistics();
      }
    });

    this.projectDataBusService.projectObservable.subscribe((project) => {
      if (null === project) {
        this.isCustomer = true;
      }

      const currentRoute = this.router.url;
      if (currentRoute.startsWith('/customers')) {
        this.isCustomer = true;
      }
    });
  }

  ngOnInit() {}

  async loadStatistics() {
    const id = this.updateId;

    const statistics = await this.updateService.getStatistics(id);
    this.sourcesChartData = {
      labels: Object.keys(statistics.source),
      datasets: [
        {
          data: Object.values(statistics.source),
        },
      ],
    };

    this.devicesChartData = {
      labels: Object.keys(statistics.devices),
      datasets: [
        {
          data: Object.values(statistics.devices),
          backgroundColor: Object.keys(statistics.devices).map((label) => {
            switch (label) {
              case 'iOS':
                return '#3373ea';
              case 'Android':
                return '#50c878';
              case 'web':
                return '#f5b049';
              default:
                return '#cccccc';
            }
          }),
        },
      ],
    };

    this.appsChartData = {
      labels: Object.keys(statistics.apps),
      datasets: [
        {
          data: Object.values(statistics.apps),
        },
      ],
    };

    this.reactionsChartData = {
      labels: Object.keys(statistics.reactions),
      datasets: [
        {
          data: Object.values(statistics.reactions),
          backgroundColor: Object.keys(statistics.reactions).map((label) => {
            switch (label) {
              case 'like':
                return '#3373ea';
              case 'awesome':
                return '#f5b049';
              case 'idea':
                return '#49da16';
              case 'worried':
                return '#a800f2';
              case 'dislike':
                return '#fc5447';
              default:
                return '#cccccc';
            }
          }),
        },
      ],
    };

    const interactions = await this.updateService.getInteractions(id);
    if (Object.keys(interactions).length) {
      this.interactionsChartData = {
        labels: Object.keys(interactions),
        datasets: Object.keys(interactions[Object.keys(interactions)[0]]).map(
          (key) => {
            return {
              label: key,
              data: Object.keys(interactions).map(
                (date) => +interactions[date][key]
              ),
              backgroundColor: () => {
                switch (key) {
                  case 'update_open':
                    return '#3373ea';
                  case 'update_view':
                    return '#f5b049';
                  case 'notification_clicked':
                    return '#50c878';
                  case 'poll_react':
                    return '#a800f2';
                  case 'update_react':
                    return '#fc5447';
                  default:
                    return '#cccccc';
                }
              },
            };
          }
        ),
      };
    }

    this.ready = true;
  }

  async mapLoaded(event: any) {
    const userLocations = await this.updateService.getUserLocations(
      this.updateId
    );
    const features = userLocations.map((location) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude],
      },
    }));
    const map: Map = event.target;
    map.addSource('users-location', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: features,
      },
    });

    map.addLayer({
      id: 'heatmap-layer',
      type: 'heatmap',
      source: 'users-location',
    });

    if (features.length) {
      const bounds = new mapboxgl.LngLatBounds();
      features.forEach((feature) => {
        bounds.extend(feature.geometry.coordinates);
      });

      map.fitBounds(bounds, { padding: 25, animate: false, maxZoom: 10 });
    } else {
      map.setCenter([4.9041, 52.3676]).setZoom(3);
    }
  }

  async loadUpdate() {
    try {
      this.update = await this.updateService.fetch(this.updateId);
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  async deleteConfirmed(update: Update): Promise<void> {
    await this.updateService.remove(update);
    this.location.back();
  }

  openModal(key: string) {
    this.modalTitle = this.translateService.instant(
      `projects.detail.update.stats.${key}`
    );
    this.modalDesc = this.translateService.instant(
      `projects.detail.update.stats.${key}.desc`
    );
    this.modal.open();
  }
}
